import React from 'react'
import HomeTopStories from '../HomeTopStories'

const Home = () => {
  return (
    <div><HomeTopStories/></div>
    
  )
}

export default Home